<template>
  <div class="standingBtnDistribute">
    <DsnPanel>
      <div slot="header" class="title clearfix">
        <!-- 查询条件start -->
        <el-form
          :model="standingBtnDistributeForm"
          ref="standingBtnDistributeForm"
          :inline="true"
          class="standingBtnDistributeForm"
        >
          <el-form-item label="产线:" prop="workCenter">
            <dsn-input
              v-model.trim="standingBtnDistributeForm.workCenter"
              placeholder="请输入产线"
            ></dsn-input>
          </el-form-item>
          <el-form-item label="站位:" prop="station">
            <dsn-input
              v-model.trim="standingBtnDistributeForm.station"
              placeholder="请输入站位"
            ></dsn-input>
          </el-form-item>
          <el-form-item>
            <dsn-button-query v-if="hasQueryBtnAuth" @click="handleQuery"
              >查询</dsn-button-query
            >
            <dsn-button
              type
              plain
              icon="el-icon-refresh"
              class="reset-button"
              @click="handleReset"
              >重置</dsn-button
            >
          </el-form-item>
        </el-form>
        <!-- 查询条件end -->
      </div>
      <!-- 表格操作start -->
      <div class="operate">
        <el-form
          :model="showInfo"
          ref="showInfo"
          :inline="true"
          class="showInfo"
        >
          <el-form-item>
            <dsn-button-add
              v-if="hasAddBtnAuth"
              :disabled="this.selectionList.length !== 0"
              @click="handleAdd"
              >新增</dsn-button-add
            >
            <dsn-button-edit
              v-if="hasEditBtnAuth"
              :disabled="this.selectionList.length !== 1"
              @click="handleEdit"
              >编辑</dsn-button-edit
            >
            <dsn-button-delete
              :disabled="this.selectionList.length !== 1"
              @click="handleDelete"
              >删除</dsn-button-delete
            >
          </el-form-item>
          <el-form-item label="产线描述:" prop="station">
            <el-input
              v-model.trim="showInfo.station"
              :disabled="true"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="站位描述:" prop="station">
            <el-input
              v-model.trim="showInfo.station"
              :disabled="true"
              size="small"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格操作end -->
      <!-- 表格数据start -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        height="550px"
        :span-method="cellMerge"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <!-- <el-table-column type="index" width="70" label="序号" ></el-table-column> -->
        <!-- <el-table-column label="序号" width="70px">
          <template slot-scope="scope">{{scope.$index+1}}</template>
        </el-table-column>-->
        <el-table-column prop="workCenter" label="产线"></el-table-column>
        <el-table-column prop="workCenterDes" label="产线描述"></el-table-column>
        <el-table-column prop="station" label="站位"></el-table-column>
        <el-table-column prop="stationDes" label="站位描述"></el-table-column>
        <el-table-column
          prop="stationButton"
          label="所分配按钮"
          show-overfslow-tooltip
        ></el-table-column>
      </el-table>
      <!-- 表格数据end -->
      <!-- 分页start -->
      <DsnFooter>
        <dsn-pagination
          background
          layout="->,total,prev,pager,next,sizes"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          @size-change="handlePagesize"
          @current-change="handleCurrentChange"
          v-if="showPage"
        ></dsn-pagination>
      </DsnFooter>
      <!-- 分页end -->
    </DsnPanel>
  </div>
</template>

<script>
import {
  findPageHttp,
  deleteHttp,
} from "@/api/base/standing.btn.distribute.js";
import DsnFooter from "../../layout/dsn-footer";
import { mapMutations } from "vuex";
import {
  BTN_STANDING_BUTTON_DISTRIBUTE_QUERY,
  BTN_STANDING_BUTTON_DISTRIBUTE_ADD,
  BTN_STANDING_BUTTON_DISTRIBUTE_EDIT,
} from "@/constant/";

export default {
  name: "StandingButtonDistribute",
  components: {
    DsnFooter,
  },
  data() {
    return {
      spanWorkCenterArr: [],
      spanWorkCenterDesArr: [],
      spanStationArr: [],
      spanStationDesArr: [],
      tableData: [],
      selectionList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      standingBtnDistributeForm: {
        station: "",
        workCenter: "",
      },
      showInfo: {
        station: "",
      },
      showPage: true, //是否重新渲染分页
      pos: 0,
      indexNum: 0,
    };
  },
  computed: {
    hasQueryBtnAuth() {
      return this.btnList.includes(BTN_STANDING_BUTTON_DISTRIBUTE_QUERY);
    },
    hasAddBtnAuth() {
      return this.btnList.includes(BTN_STANDING_BUTTON_DISTRIBUTE_ADD);
    },
    hasEditBtnAuth() {
      return this.btnList.includes(BTN_STANDING_BUTTON_DISTRIBUTE_EDIT);
    },
  },
  methods: {
    ...mapMutations(["STANDINGBTNDISTRIBUTEEDIT"]),
    handleQuery(showPage = true) {
      if (!showPage) {
        this.currentPage = 1;
      }
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        station: this.standingBtnDistributeForm.station,
        workCenter: this.standingBtnDistributeForm.workCenter,
      };
      this.showPage = showPage;
      findPageHttp(data).then((data) => {
        this.showPage = true;
        const res = data.data;
        if (res.code === 200) {
          this.total = res.data.total;
          this.tableData = res.data.data;
          this.getSpanWorkCenterArr(this.tableData);
          this.getSpanStationArr(this.tableData);
          this.getSpanWorkCenterDesArr(this.tableData);
          this.getSpanStationDesArr(this.tableData);
          return;
        }
        this.notification().error({
          body: res.message,
        });
      });
    },
    cellMerge({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanStationArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex === 1) {
        const _row = this.spanWorkCenterArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex === 2) {
        const _row = this.spanWorkCenterDesArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex === 3) {
        const _row = this.spanStationArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }

      if (columnIndex === 4) {
        const _row = this.spanStationDesArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    getSpanWorkCenterArr(data) {
      this.spanWorkCenterArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanWorkCenterArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素工作中心与上一个元素是否相同
          if (data[i].workCenter === data[i - 1].workCenter) {
            this.spanWorkCenterArr[this.pos] += 1;
            this.spanWorkCenterArr.push(0);
          } else {
            this.spanWorkCenterArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    getSpanStationArr(data) {
      this.spanStationArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanStationArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素站位与上一个元素是否相同
          if (data[i].station === data[i - 1].station) {
            this.spanStationArr[this.pos] += 1;
            this.spanStationArr.push(0);
          } else {
            this.spanStationArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    getSpanWorkCenterDesArr(data) {
      this.spanWorkCenterDesArr = [];
      this.spanStationDesArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanWorkCenterDesArr.push(1);
          // this.spanStationDesArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素工作中心描述与上一个元素是否相同
          if (data[i].workCenterDes === data[i - 1].workCenterDes) {
            this.spanWorkCenterDesArr[this.pos] += 1;
            this.spanWorkCenterDesArr.push(0);
          } else {
            this.spanWorkCenterDesArr.push(1);
            this.pos = i;
          }
          // // 判断当前元素站位描述与上一个元素是否相同
          // if (data[i].stationDes === data[i - 1].stationDes) {
          //   this.spanStationDesArr[this.pos] += 1;
          //   this.spanStationDesArr.push(0);
          // } else {
          //   this.spanStationDesArr.push(1);
          //   this.pos = i;
          // }
        }
      }
    },
    getSpanStationDesArr(data) {
      this.spanStationDesArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanStationDesArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素站位描述与上一个元素是否相同
          if (data[i].stationDes === data[i - 1].stationDes) {
            this.spanStationDesArr[this.pos] += 1;
            this.spanStationDesArr.push(0);
          } else {
            this.spanStationDesArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // // 自定义索引
    // indexMethod(index) {

    // },
    handleReset() {
      this.$refs["standingBtnDistributeForm"].resetFields();
      this.$refs["showInfo"].resetFields();
      this.tableData = [];
      this.selectionList = [];
    },
    handleAdd() {
      this.STANDINGBTNDISTRIBUTEEDIT(null);
      this.$router.push({
        name: "standingButtonDistributeEdit",
        query: { operateType: "add" },
      });
    },
    handleEdit() {
      this.STANDINGBTNDISTRIBUTEEDIT(this.selectionList[0]);
      this.$router.push({
        name: "standingButtonDistributeEdit",
        query: { operateType: "edit" },
      });
    },
    handleDelete() {
      this.messageBox()
        .then(() => {
          console.log(this.selectionList);
          let params = {
            station: this.selectionList[0].station,
            workCenter: this.selectionList[0].workCenter,
          };
          deleteHttp(params).then((data) => {
            if (data.data.code == 200) {
              this.notification().success({
                body: "删除成功",
              });
              this.handleQuery();
            }
          });
        })
        .catch(() => {
          this.notification().warn({
            body: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    //更改当前页码,再次请求数据
    handleCurrentChange(currentChange) {
      this.currentPage = currentChange;
      this.handleQuery();
    },
    //更改页码大小
    handlePagesize(pageSize) {
      this.pageSize = pageSize;
      this.handleQuery();
    },
  },
};
</script>

<style lang="scss">
.standingBtnDistribute {
  padding-bottom: 50px;
  .dsn-button {
    margin: 0 8px 16px 0;
  }
}
</style>
