<template>
  <div class="standingBtnDistributeEdit">
    <div class="operate">
      <dsn-button
        size="small"
        type="primary"
        icon="el-icon-back"
        @click="handleBack"
        >返回</dsn-button
      >
      <!-- <dsn-button size="small" type="primary" v-if="hasSaveBtnAuth" @click="handleSaveButton">保存</dsn-button> -->
      <dsn-button-save v-if="hasSaveBtnAuth" @click="handleSaveButton"
        >保存</dsn-button-save
      >
      <dsn-button
        type
        plain
        icon="el-icon-refresh"
        class="reset-button"
        @click="handleReset"
        >重置</dsn-button
      >
    </div>
    <div class="info">
      <el-form
        :model="standingBtnDistributeForm"
        ref="standingBtnDistributeForm"
        :rules="standingBtnDistributeFormRules"
        :inline="true"
        class="standingBtnDistributeForm"
      >
        <div>
          <el-form-item label="产线:" prop="workCenter">
            <dsn-input
              v-model.trim="standingBtnDistributeForm.workCenter"
              :disabled="operateType === 'edit'"
              placeholder="暂无产线"
              style="vertical-align: baseline;"
            >
              <el-button
                slot="append"
                @click="handleOpenDialog"
                icon="el-icon-document"
              ></el-button>
            </dsn-input>
          </el-form-item>
          <el-form-item label="产线描述:" prop="workCenterDes">
            <dsn-input
              disabled
              v-model.trim="standingBtnDistributeForm.workCenterDes"
              placeholder="暂无产线描述"
            ></dsn-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="站位:" prop="station">
            <dsn-input
              :disabled="operateType === 'edit'"
              v-model.trim="standingBtnDistributeForm.station"
              placeholder="暂无站位"
              style="vertical-align: baseline;"
            >
              <el-button
                slot="append"
                @click="handleOpenDialog"
                icon="el-icon-document"
              ></el-button>
            </dsn-input>
          </el-form-item>
          <el-form-item label="站位描述:" prop="stationDes">
            <dsn-input
              disabled
              v-model.trim="standingBtnDistributeForm.stationDes"
              placeholder="暂无站位描述"
            ></dsn-input>
          </el-form-item>
        </div>
      </el-form>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="按钮分配" name="btnDistribute">
          <DsnShuttleTable
            ref="filterTable"
            :getKey="getKey"
            :search="search"
            v-model="transferTableData"
            :rightDefaultChecked="[...rightDefaultChecked]"
            @select-change="transferSelectChange"
          >
            <template slot="left-title">未分配按钮</template>
            <template slot="right-title">已分配按钮</template>
            <template slot="table">
              <el-table-column prop="btn" label="按钮"></el-table-column>
              <el-table-column prop="des" label="按钮描述"></el-table-column>
            </template>
          </DsnShuttleTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 查询产线站位start -->
    <DsnSelectDialog
      title="产线站位选择"
      :visible.sync="queryDialog"
      width="800px"
      :helpText="helpText"
      :tableData="tableData"
      v-model="selectedList"
      @confirm="handleConfirmSelect"
      @cancle="handleCancleSelect"
      @rowDbClick="rowDbClick"
      :maxShowLimit="1000"
      row-key="workCenter"
      ref="workCenterChoice"
      :isLoading="loadingOperation"
      keyValue="workCenter"
      :isSingle="true"
    >
      <template slot="header">
        <el-form
          :model="workCenterConditionForm"
          class="workCenterConditionForm"
          ref="workCenterConditionForm"
          :inline="true"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="产线：" prop="workCenter">
                <dsn-input
                  v-model.trim="workCenterConditionForm.workCenter"
                  size="small"
                  placeholder="产线"
                ></dsn-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="站位:" prop="station">
                <dsn-input
                  v-model.trim="workCenterConditionForm.station"
                  size="small"
                  placeholder="站位"
                ></dsn-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <dsn-button-query
                  icon="el-icon-transferSearch"
                  @click="handleQueryWorkCenter"
                >
                  查询
                </dsn-button-query>
                <dsn-button
                  size="small"
                  type="primary"
                  icon="el-icon-refresh"
                  @click="handleResetWorkCenterCondition"
                >
                  重置
                </dsn-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template slot="body">
        <vxe-table-column prop="workCenter" label="产线"></vxe-table-column>
        <vxe-table-column
          prop="workCenterDes"
          label="产线描述"
          show-overflow-tooltip
        ></vxe-table-column>
        <vxe-table-column prop="station" label="站位"></vxe-table-column>
        <vxe-table-column
          prop="stationDes"
          label="站位描述"
          show-overflow-tooltip
        ></vxe-table-column>
      </template>
    </DsnSelectDialog>
    <!-- 查询产线站位end -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  listAssignedButton,
  listUnassingButton,
  saveButtonHttp,
  createButtonHttp,
  listStationByWCRorStationHttp,
} from "@/api/base/standing.btn.distribute.js";
import _ from "lodash";
import { BTN_STANDING_BUTTON_DISTRIBUTE_SAVE } from "@/constant/";
export default {
  name: "StandingButtonDistributeEdit",
  data() {
    return {
      operateType: "",
      cloneStandingBtnDistributeEdit: null,
      standingBtnDistributeForm: {
        station: "",
        workCenter: "",
        stationDes: "",
        workCenterDes: "",
      },
      standingBtnDistributeFormRules: {
        station: { required: true, message: "请输入站位", trigger: "blur" },
        workCenter: { required: true, message: "请输入产线", trigger: "blur" },
      },
      // 工作中心 CX001  站位  ZW004
      activeName: "btnDistribute",
      // 产线查询
      queryDialog: false,
      workCenterConditionForm: {
        workCenter: "",
        station: "",
      },
      tableData: [],
      selectedList: [],
      // 站位查询
      stationDialog: false,
      stationConditionForm: {
        station: "",
        stationDes: "",
      },
      stationData: [],
      selectedStationList: [],
      cloneUnassingButtonList: [],
      cloneAssignedButtonList: [],
      loadingOperation: false,
      // 穿梭框数据
      transferTableData: [],
      transferSelectedArr: [],
      options: {
        tag: undefined,
      },
      rightData: [],
      rightDefaultChecked: [],
      getTransferAllData: [],
    };
  },
  computed: {
    ...mapGetters(["standingBtnDistributeEdit"]),
    hasSaveBtnAuth() {
      return this.btnList.includes(BTN_STANDING_BUTTON_DISTRIBUTE_SAVE);
    },
  },
  created() {
    this.operateType = this.$route.query.operateType;

    if (this.operateType === "edit") {
      this.standingBtnDistributeForm = _.cloneDeep(
        this.standingBtnDistributeEdit
      );
      this.cloneStandingBtnDistributeEdit = _.cloneDeep(
        this.standingBtnDistributeEdit
      );
      // 查询已分配的
      this.handleListAssignedButton();
    }
    // 查询未分配的
    this.handleListUnassingButton();
  },
  methods: {
    //返回
    handleBack() {
      // this.$router.push({ name: "standingButtonDistribute" });
      this.$router.pushAndClear(
        { name: "standingButtonDistribute" },
        {
          deleteItem: this.$route,
          current: this.$route,
        }
      );
    },
    //重置
    handleReset() {
      if (this.operateType === "add") {
        this.$refs["standingBtnDistributeForm"].resetFields();
      }
      if (this.operateType === "edit") {
        this.standingBtnDistributeForm = _.cloneDeep(
          this.cloneStandingBtnDistributeEdit
        );
      }
      this.transferTableData = _.cloneDeep(this.cloneUnassingButtonList);
      this.rightDefaultChecked = _.cloneDeep(this.cloneAssignedButtonList);
    },
    handleListAssignedButton() {
      listAssignedButton({
        station: this.standingBtnDistributeForm.station,
        workCenter: this.standingBtnDistributeForm.workCenter,
      }).then((data) => {
        const res = data.data;
        if (res.code == 200) {
          const tempArr = res.data.map((item) => {
            const arr = item.split("-");
            return {
              btn: arr[0],
              des: arr[1],
            };
          });
          this.rightDefaultChecked = _.cloneDeep(tempArr);
          this.cloneAssignedButtonList = _.cloneDeep(tempArr);
        } else {
          this.notification().error({
            body: res.message,
          });
        }
      });
    },
    handleListUnassingButton() {
      listUnassingButton({
        station: this.standingBtnDistributeForm.station,
        workCenter: this.standingBtnDistributeForm.workCenter,
      }).then((data) => {
        const res = data.data;
        if (res.code == 200) {
          let tempData = [];
          tempData = res.data.map((item) => {
            const arr = item.split("-");
            return {
              btn: arr[0],
              des: arr[1],
            };
          });
          tempData = tempData.filter((item) =>
            this.rightDefaultChecked.indexOf(item)
          );
          this.transferTableData = _.cloneDeep(tempData);
          this.cloneUnassingButtonList = _.cloneDeep(tempData);
        } else {
          this.notification().error({
            body: res.message,
          });
        }
      });
    },
    handleSaveButton() {
      this.$refs["standingBtnDistributeForm"].validate((valid) => {
        if (valid) {
          if (this.rightDefaultChecked.length <= 0) {
            this.notification().warn({
              body: "请至少选择一项",
            });
            return;
          }
          const arr = this.rightDefaultChecked.map((item) => {
            return `${item.btn}-${item.des}`;
          });
          const data = { ...this.standingBtnDistributeForm, buttonList: arr };
          if (this.operateType === "add") {
            this.addHttp(data);
          }
          if (this.operateType === "edit") {
            this.editHttp(data);
          }
        } else {
          return false;
        }
      });
    },
    addHttp(data) {
      createButtonHttp(data).then((data) => {
        const res = data.data;
        if (res.code == 200) {
          this.notification().success({
            body: "新增成功",
          });
        } else {
          this.notification().error({
            body: res.message,
          });
        }
      });
    },
    editHttp(data) {
      saveButtonHttp(data).then((data) => {
        const res = data.data;
        if (res.code == 200) {
          this.notification().success({
            body: "修改成功",
          });
        } else {
          this.notification().error({
            body: res.message,
          });
        }
      });
    },
    handleOpenDialog() {
      this.queryDialog = true;
      this.workCenterConditionForm.workCenter = this.standingBtnDistributeForm.workCenter;
      this.workCenterConditionForm.station = this.standingBtnDistributeForm.station;
      this.handleQueryWorkCenter();
    },
    //查询产线start
    handleQueryWorkCenter() {
      const data = {
        ...this.workCenterConditionForm,
      };
      listStationByWCRorStationHttp(data).then((response) => {
        const { code, message, data } = response.data;
        if (code === 200) {
          this.tableData = data;
          return;
        }
        this.notification().error({
          body: message,
        });
      });
    },
    //重置查询条件
    handleResetWorkCenterCondition() {
      this.$refs["workCenterConditionForm"].resetFields();
      this.tableData = [];
      this.selectedList = [];
      this.$refs["querySingleDialog"].handleClear();
      this.handleQueryWorkCenter();
    },
    //确认选择lot
    handleConfirmSelect(val) {
      this.standingBtnDistributeForm.workCenter = val[0].workCenter;
      this.standingBtnDistributeForm.workCenterDes = val[0].workCenterDes;
      this.standingBtnDistributeForm.station = val[0].station;
      this.standingBtnDistributeForm.stationDes = val[0].stationDes;
      this.queryDialog = false;
    },
    //取消
    handleCancleSelect() {
      this.queryDialog = false;
    },
    rowDbClick(row) {
      this.standingBtnDistributeForm.workCenter = row.workCenter;
      this.standingBtnDistributeForm.workCenterDes = row.workCenterDes;
      this.standingBtnDistributeForm.station = row.station;
      this.standingBtnDistributeForm.stationDes = row.stationDes;
      this.operationDialog = false;
      // this.handleResetCondition("close");
    },
    helpText(item) {
      return item["workCenter"];
    },
    // 穿梭框方法start
    getKey(item) {
      return item["btn"] + "_" + item["des"];
    },
    transferSelectChange(rightData) {
      this.rightDefaultChecked = _.cloneDeep(rightData);
    },
    search(item, key) {
      if (key == "") {
        return true;
      }
      return item["btn"].indexOf(key) != -1;
    },
    // 穿梭框方法end
  },
};
</script>

<style lang="scss">
.standingBtnDistributeEdit {
  .operate {
    padding-top: 16px;
    .dsn-button {
      margin: 0 8px 16px 0;
    }
  }
  .info {
    background-color: #ffffff;
    padding: 16px;
    .el-tabs {
      .el-tab-pane {
        .container {
          display: flex;
          .left {
            // flex: 1;
            width: 40%;
            input {
              margin: 10px 0;
            }
          }
          .right {
            // flex: 1;
            width: 40%;
            input {
              margin: 10px 0;
            }
          }
          .ope {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            .dsn-button + .dsn-button {
              margin-left: 0px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
