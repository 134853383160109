import request from "@/service/";
import qs from "qs";
// import {VUE_APP_URL} from '@/config'

/**
 * 分页查询站位按钮
 *  url /stationButton/findPage
 */
export const findPageHttp = (data) => {
  return request.get(`${window.VUE_APP_URL}/mes/stationButton/findPage`, {
    params: data,
  });
};

/**
 * 查询已分配按钮String数组列表
 * @param {*} data
 */
export const listAssignedButton = (data) => {
  return request.get(
    `${window.VUE_APP_URL}/mes/stationButton/listAssignedButton`,
    {
      params: data,
    }
  );
};

/**
 * 查询未分配按钮String数组列表
 * @param {*} data
 */
export const listUnassingButton = (data) => {
  return request.get(
    `${window.VUE_APP_URL}/mes/stationButton/listUnassingButton`,
    {
      params: data,
    }
  );
};

/**
 *新增站位按钮
 * url /stationButton/create
 * RequestBody的按钮数组(LOGIN_IN-登录)格式，workCenter工作中心，station站位
 */
export const createButtonHttp = (data) => {
  const params = qs.stringify({
    station: data.station,
    workCenter: data.workCenter,
  });
  return request.post(
    `${window.VUE_APP_URL}/mes/stationButton/create?${params}`,
    data.buttonList
  );
};

/**
 * save保存站位按钮
 * url /stationButton/save
 * RequestBody的按钮数组(LOGIN_IN-登录)格式，workCenter工作中心，station站位
 */
export const saveButtonHttp = (data) => {
  const params = qs.stringify({
    station: data.station,
    workCenter: data.workCenter,
  });
  return request.post(
    `${window.VUE_APP_URL}/mes/stationButton/save?${params}`,
    data.buttonList
  );
};

/**
 * 查询产线和站位
 * url /stationButton/listStationByWCRorStation
 * workCenter产线，station站位，可以单独输入也可以一起输入
 */
export const listStationByWCRorStationHttp = (data) => {
  return request.get(
    `${window.VUE_APP_URL}/mes/stationButton/listStationByWCRorStation`,
    { params: data }
  );
};

/**
 * 站位按钮删除
 * url /stationButton/delete
 * 根据站位和产线删除已分配按钮
 */
export const deleteHttp = (data) => {
  const params = qs.stringify({
    station: data.station,
    workCenter: data.workCenter,
  });
  return request.post(
    `${window.VUE_APP_URL}/mes/stationButton/delete?${params}`,
    data
  );
};
